import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import RefDataDate from '../components/RefDataDate';
import { navigate } from 'gatsby'
import Mapbox from '../mapbox'
import Calculate from '../util/calculate';

import { Select } from 'antd';
import './index.css';
import ImageBG from '/images/oosc-bg.jpg';

import children_oosc from '/images/oosc-1.png';
import children from '/images/oosc-4.png';
import children2 from '/images/oosc-5.png';
import money from '/images/oosc-2.png';
import paper from '/images/oosc-3.png';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import HighchartsItemSeries from 'highcharts/modules/item-series'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TumbonAddr from '../util/TumbonAddr'
import ENG_Addrees from '../util/ENG_Addrees'
import useTranslation from '../hook/useTranslation'
import api from '../util/api'
const { Option } = Select;

if (typeof Highcharts === 'object') {
  HighchartsItemSeries(Highcharts);
}
const LIST_PROVICNE = [
  "กาญจนบุรี",
  "ขอนแก่น",
  "นครนายก",
  "นครราชสีมา",
  "น่าน",
  "พิษณุโลก",
  "ภูเก็ต",
  "มหาสารคาม",
  "ยะลา",
  "ระยอง",
  "ลำปาง",
  "สงขลา",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "สุโขทัย",
  "อำนาจเจริญ",
  "อุบลราชธานี",
  "เชียงใหม่",
  "แพร่",
  "แม่ฮ่องสอน"
]


const PageOOSC = (props) => {
  const [T, setLang, lang] = useTranslation()
  const [open, setOpen] = useState(true)
  const [tabvalue, setTabValue] = React.useState(0);
  const [data_map, setDataMap] = useState([])
  const [border_color] = useState(['#1b5e20', '#388e3c', '#66bb6a', '#a5d6a7', '#94a3b8',])
  const [initData, setInitData] = useState(null)
  const is_Click = useRef(false);

  const [pageData, setPageData] = useState({
    oosc_survey_3_5: 0,
    oosc_survey_6_11: 0,
    oosc_survey_12_14: 0,
    oosc_survey_15_17: 0,
    age_target_03_05: 0,
    age_target_06_11: 0,
    age_target_12_14: 0,
    age_target_15_17: 0,
    total_target: 0,
    sum_survey: 0,
    percent3_5: 0,
    percent6_11: 0,
    percent12_14: 0,
    percent15_17: 0
  })

  const [filter, setFilter] = React.useState({
    view: 'country',
    year: "2563",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    checkbox_side: []
  });

  useEffect(async () => {
    let res = await api.getInitData('oosc')
    let oosc_survey_3_5 = res.data.reduce((acc, value) => acc + value.year2563_oosc_survey_3_5, 0)
    let oosc_survey_6_11 = res.data.reduce((acc, value) => acc + value.year2563_oosc_survey_6_11, 0)
    let oosc_survey_12_14 = res.data.reduce((acc, value) => acc + value.year2563_oosc_survey_12_14, 0)
    let oosc_survey_15_17 = res.data.reduce((acc, value) => acc + value.year2563_oosc_survey_15_17, 0)

    let age_target_03_05 = res.data.reduce((acc, value) => acc + value.year2563_age_target_3_5, 0)
    let age_target_06_11 = res.data.reduce((acc, value) => acc + value.year2563_age_target_6_11, 0)
    let age_target_12_14 = res.data.reduce((acc, value) => acc + value.year2563_age_target_12_14, 0)
    let age_target_15_17 = res.data.reduce((acc, value) => acc + value.year2563_age_target_15_17, 0)
    let total_target = res.data.reduce((acc, value) => acc + value.year2563_total_target, 0)

    let sum_survey = (
      oosc_survey_3_5 +
      oosc_survey_6_11 +
      oosc_survey_12_14 +
      oosc_survey_15_17
    )
    let percent3_5 = (oosc_survey_3_5 / sum_survey) * 100
    let percent6_11 = (oosc_survey_6_11 / sum_survey) * 100
    let percent12_14 = (oosc_survey_12_14 / sum_survey) * 100
    let percent15_17 = 100 - (percent3_5 + percent6_11 + percent12_14)
    setPageData({
      oosc_survey_3_5,
      oosc_survey_6_11,
      oosc_survey_12_14,
      oosc_survey_15_17,
      age_target_03_05,
      age_target_06_11,
      age_target_12_14,
      age_target_15_17,
      total_target,
      sum_survey,
      percent3_5,
      percent6_11,
      percent12_14,
      percent15_17,
    })
    setInitData(res.data)
  }, [])

  useEffect(() => {
    if (initData) {
      let response = onProcessData({
        province: filter['province_name'],
        city: filter['city_name'],
        tumbon: filter['tumbon_name'],
        layer: filter.view
      }, convertMapContent(filter.view, initData.map(x => ({ ...x, total: 1 }))))
      if (!filter.province_name || filter.province_name !== 'ทั้งหมด') {
        if (filter.city_name !== null) {
        } else {
        }
      }
      let border_filter = response.data
      if (filter.checkbox_side.length > 0) {
        if (filter.view === 'country') {
          border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.province))
          console.log(filter.checkbox_side, border_filter)
        } else if (filter.view === 'province') {
          border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.city))
        } else if (filter.view === 'city') {
          border_filter = response.data.filter(item => !filter.checkbox_side.includes(item.tumbon))
        }
      }
      setDataMap([
        { id: 'border', layer: filter.view, data: border_filter },
      ])
    }

  }, [initData, filter])



  const onclickBorder = (layer, filterBorder) => {
    if (layer === 'tumbon') return;
    if (!is_Click.current) {
      is_Click.current = true
      let _filter = { ...filter }
      if (layer === 'province' && filterBorder.province) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'province'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = null
        _filter['tumbon_name'] = null
        _filter['checkbox_side'] = []
      } else if (layer === 'city' && filterBorder.city) {
        _filter['year'] = filterBorder.year
        _filter['view'] = 'city'
        _filter['province_name'] = filterBorder.province
        _filter['city_name'] = filterBorder.city
        _filter['tumbon_name'] = null
        _filter['checkbox_side'] = []
      }
      setFilter(_filter)
      setTimeout(() => {
        is_Click.current = false
      }, 1000)
    }
  }

  const onBack = () => {
    let _filter = { ...filter }
    if (_filter.view === 'country') return;
    if (_filter.view === 'province') {
      _filter['view'] = 'country'
      _filter['province_name'] = 'ทั้งหมด'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    } else if (_filter.view === 'city') {
      _filter['view'] = 'province'
      _filter['city_name'] = null
      _filter['tumbon_name'] = null
    }
    setFilter(_filter)
  }

  const convertMapContent = (layer, data) => {
    let res = []
    const province = [...new Set(TumbonAddr.map(item => item.provincename))].map(item => {
      return {
        province: item,
        // total: 0,
        // student: 0,
        // province20: 0,
        oosc3_5: 0,
        oosc6_11: 0,
        oosc12_14: 0,
        oosc15_17: 0,
        // total_target: 0,
        // total_oosc: 0,
        // sum_oosc: 0,
        total_target_map_oosc: 0,
        province20_map_oosc: 0,
      }
    });
    const city = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname))].map(item => {
      return {
        province: item.split('_')[0],
        city: item.split('_')[1],
        // total: 0,
        // student: 0,
        // province20: 0,
        oosc3_5: 0,
        oosc6_11: 0,
        oosc12_14: 0,
        oosc15_17: 0,
        // total_target: 0,
        // total_oosc: 0,
        // sum_oosc: 0,
        total_target_map_oosc: 0,
        province20_map_oosc: 0,
      }
    });
    const tumbon = [...new Set(TumbonAddr.map(item => item.provincename + '_' + item.cityname + '_' + item.tumbonname))].map(item => {
      return {
        province: item.split('_')[0], city: item.split('_')[1],
        tumbon: item.split('_')[2],
        // total: 0,
        // student: 0,
        // province20: 0,
        oosc3_5: 0,
        oosc6_11: 0,
        oosc12_14: 0,
        oosc15_17: 0,
        // total_target: 0,
        // total_oosc: 0,
        // sum_oosc: 0,
        percent_table: 0,
        total_target_map_oosc: 0,
        province20_map_oosc: 0,
      }
    });
    data.forEach((item) => {

      let total = (item.province20)
      let count = (item.province20)
      let sum_oosc = (
        item.oosc_survey_3_5 +
        item.oosc_survey_6_11 +
        item.oosc_survey_12_14 +
        item.oosc_survey_15_17
      )

      if (layer === 'country') {
        let index = province.findIndex(ele => ele.province === item.province_name)
        province[index]['oosc3_5'] += item[`year${filter.year}_oosc_survey_3_5`]
        province[index]['oosc6_11'] += item[`year${filter.year}_oosc_survey_6_11`]
        province[index]['oosc12_14'] += item[`year${filter.year}_oosc_survey_12_14`]
        province[index]['oosc15_17'] += item[`year${filter.year}_oosc_survey_15_17`]
        province[index]['total_target_map_oosc'] += item[`year${filter.year}_total_target`]
        province[index]['province20_map_oosc'] += item[`year${filter.year}_oosc_total_survey`]
        res = province
      } else if (layer === 'province') {
        let index = city.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name)
        if (city[index]) {
          city[index]['oosc3_5'] += item[`year${filter.year}_oosc_survey_3_5`]
          city[index]['oosc6_11'] += item[`year${filter.year}_oosc_survey_6_11`]
          city[index]['oosc12_14'] += item[`year${filter.year}_oosc_survey_12_14`]
          city[index]['oosc15_17'] += item[`year${filter.year}_oosc_survey_15_17`]
          city[index]['total_target_map_oosc'] += item[`year${filter.year}_total_target`]
          city[index]['province20_map_oosc'] += item[`year${filter.year}_oosc_total_survey`]
          res = city
        }
      } else if (layer === 'city') {
        let index = tumbon.findIndex(ele => ele.province === item.province_name && ele.city === item.city_name && ele.tumbon === (item.province_id === '10' ? 'แขวง' + item.tumbon_name : item.tumbon_name))
        if (tumbon[index]) {
          tumbon[index]['oosc3_5'] += item[`year${filter.year}_oosc_survey_3_5`]
          tumbon[index]['oosc6_11'] += item[`year${filter.year}_oosc_survey_6_11`]
          tumbon[index]['oosc12_14'] += item[`year${filter.year}_oosc_survey_12_14`]
          tumbon[index]['oosc15_17'] += item[`year${filter.year}_oosc_survey_15_17`]
          tumbon[index]['total_target_map_oosc'] += item[`year${filter.year}_total_target`]
          tumbon[index]['province20_map_oosc'] += item[`year${filter.year}_oosc_total_survey`]
          res = tumbon
        }
      }
    })
    return res
  }

  const ReanderChartItem = ({ data }) => {
    let {
      percent3_5,
      percent6_11,
      percent12_14,
      percent15_17
    } = data
    return (
      <HighchartsReact
        highcharts={Highcharts}
        options={{

          chart: {
            type: 'item',
            // width: '100%',
            height: '150px',
          },

          credits: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          title: {
            text: null
          },

          subtitle: {
            text: null
          },

          series: [{
            name: 'ร้อยละ',
            data: [
              {
                name: `ช่วงอายุ 3-5 ปี`,
                y: percent3_5 ? parseFloat(percent3_5.toFixed(2)) : 1,
                color: '#00C896'
              }
              , {
                name: `ช่วงอายุ 6-11 ปี`,
                // y: 12,
                y: percent6_11 ? parseFloat(percent6_11.toFixed(2)) : 1,
                color: '#24F52C'
              },
              {
                name: `ช่วงอายุ 12-14 ปี`,
                // y: 12,
                y: percent12_14 ? parseFloat(percent12_14.toFixed(2)) : 1,
                color: '#4BAF67'
              },
              {
                name: `ช่วงอายุ 15-17 ปี`,
                // y: 12,
                y: percent15_17 ? parseFloat(percent15_17.toFixed(2)) : 1,
                color: '#32A49D'
              }
            ]
          }]

        }}
      />
    )
  }

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value)
      let color = ''
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color
          break;
        }
      }
      return color
    }

    let result = []
    data.forEach(element => {
      if (filter.province && filter.province !== 'ทั้งหมด' && !filter.city && !filter.tumbon) {
        if (filter.province === element.province) {
          result.push(element)
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city) {
          result.push(element)
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (filter.province === element.province && filter.city === element.city && filter.tumbon === element.tumbon) {
          result.push(element)
        }
      } else {
        result.push(element)
      }
    })

    result.map(element => {
      if (!isFinite(element['province20_map_oosc'] / element['province20_map_oosc'])) {
        element['percent'] = 0
      } else {
        element['percent'] = ((element['province20_map_oosc'] / result.map(item => item.province20_map_oosc).reduce((a, b) => a + b, 0)) * 100)
      }
      // if (!isFinite(element['sum_oosc'] / element['sum_oosc'])) {
      //   element['percent'] = 0
      // } else {
      //   element['percent'] = ((element['sum_oosc'] / result.map(item => item.sum_oosc).reduce((a, b) => a + b, 0)) * 100)
      // }
      return element
    })

    let data_color = [
      { color: border_color[0], value: Calculate.Percentile(90, result.map(item => item.province20_map_oosc)), count: 0, text: `มาก` },
      { color: border_color[1], value: Calculate.Percentile(50, result.map(item => item.province20_map_oosc)), count: 0, text: `ปานกลาง` },
      { color: border_color[2], value: Calculate.Percentile(10, result.map(item => item.province20_map_oosc)), count: 0, text: `ปานกลาง` },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ]
    result.forEach(element => {
      element['color'] = _genColorRange(data_color, element.province20_map_oosc)
      element['percent'] = parseFloat(element['percent'].toFixed(2))
    })
    let res = { data: result, notic: data_color }
    return res
  }


  return (
    <Layout Role={['public-login', 'partner', 'eef']} backgroundImage={`url(${ImageBG})`}
      getLang={(translate, lang) => {
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("OOSC_Project_TITLE_1")}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("OOSC_Project_TITLE_2")}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-white w-full ">{T("OOSC_Project_TITLE_3")}</h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px]  rounded-3xl m-auto p-8 bg-white bordered">
          <div className="font-bold text-2xl mb-2 text-[#0E3165]">{T("Project_Description")}</div>
          <p className="text-lg mb-2 font-baijam">{T("OOSC_Project_Description")}</p>
          <div className="grid grid-cols-11 mt-8 gap-2">
            <div className="col-span-11 md:col-span-5 lg:col-span-3">
              <div className="font-bold text-2xl mb-2 text-[#0E3165]">{T("Target_Group")}</div>
              <div className="bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl p-2 h-[120px]">
                <div className="px-1 py-1">
                  <div className='flex flex-row justify-around '>
                    <div className="flex flex-col m-2">
                      <img className="flex self-center" src={children_oosc} alt="image for banner" width={'80px'} style={{}} />
                    </div>
                    <div className="flex-col self-center">
                      {
                        lang === 'EN' ?
                          <>Out-of-school children: <br /> ages 3-6 years old</> :
                          <div>เด็กและเยาวชนที่ไม่มีข้อมูล<br />ในระบบการศึกษา <br />ช่วงอายุ  3-17 ปี</div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-span-11 lg:col-span-8">
              <h3 className="font-bold text-2xl mb-2 text-[#0E3165] mt-7 md:mt-0">{T("Type_Support")}</h3>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl px-2 h-full md:h-[120px]">
                <div className="px-1 py-1">
                  <div className="grid grid-cols-12 w-full">
                    <div className="col-span-12 md:col-span-6 ">
                      <div className='flex flex-row justify-around '>
                        <div className='w-[65px] mt-[16px]'>
                          <img className="flex self-center" src={money} alt="image for banner" width={'100%'} />
                        </div>
                        <div className="flex flex-1 flex-col m-2">
                          <div className="font-bold text-2xl text-[#0E3165]">{T("OOSC_400")}</div>
                          <div>{T("OOSC_400_Description")}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-12 md:col-span-6 ">
                      <div className='flex flex-row justify-around '>
                        <div className='w-[65px] mt-[16px]'>
                          <img className="flex self-center" src={paper} alt="image for banner" width={'100%'} />
                        </div>
                        <div className="flex flex-1 flex-col m-2">
                          <div className="font-bold text-2xl text-[#0E3165]">{T("OOSC_Establish_Local_Mechanisms")}</div>
                          <div>{T("OOSC_Establish_Local_Mechanisms_Description")}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div className="col-span-11  mt-8">
              <div className="font-bold text-2xl mb-2 text-[#0E3165] mt-8 md:mt-0">จำนวนกลุ่มเป้าหมายปี 2563</div>
              <div className="grid grid-cols-11 gap-4 w-full">
                <div className="col-span-11 md:col-span-5 lg:col-span-3">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl  p-2">
                    <div className="px-1 py-1">
                      <div className='flex flex-row justify-around '>
                        <div className="flex flex-col m-2">
                          <img className="flex self-center" src={children} alt="image for banner" width={'84px'} style={{}} />
                        </div>
                        <div className="flex-col">
                          {
                            lang === 'EN' ?
                              <>
                                <h3 className='m-0 text-md text-right '>The total number of</h3>
                                <h3 className='m-0 text-md text-right'>out-of-school children</h3>
                                <h3 className='m-0 text-2xl text-right text-[#22C55E]'>{pageData.total_target.toLocaleString("en-US")}</h3>
                                <h3 className='m-0 text-xl text-right'>people</h3>
                              </>
                              :
                              <>
                                <h3 className='m-0 text-md text-right '>เด็กและเยาวชนที่ไม่มีข้อมูล</h3>
                                <h3 className='m-0 text-md text-right'>ในระบบการศึกษาทั้งหมด</h3>
                                <h3 className='m-0 text-2xl text-right text-[#22C55E]'>{pageData.total_target.toLocaleString("en-US")}</h3>
                                <h3 className='m-0 text-xl text-right'>คน</h3>
                              </>
                          }


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-11 lg:col-span-8">
                  <div className='flex w-full justify-around'>
                    <div className="text-center">
                      {
                        lang === 'EN' ?
                          <>
                            <h3 className='m-0 text-md'> ages 3 - 5 years</h3>
                            <h3 className='m-0 text-lg'> {pageData.age_target_03_05.toLocaleString("en-US")} persons </h3>
                          </>
                          :
                          <>
                            <h3 className='m-0' > ช่วงอายุ</h3>
                            <h3 className='m-0'> 3 - 5 ปี</h3>
                            <h3 className='m-0 text-lg'> จำนวน {pageData.age_target_03_05.toLocaleString("en-US")} คน </h3>
                          </>
                      }
                    </div>

                    <div className="text-center">
                      {
                        lang === 'EN' ?
                          <>
                            <h3 className='m-0 text-md'> ages 6 - 11 years</h3>
                            <h3 className='m-0 text-lg'> {pageData.age_target_06_11.toLocaleString("en-US")} persons </h3>
                          </>
                          :
                          <>
                            <h3 className='m-0'> ช่วงอายุ</h3>
                            <h3 className='m-0'> 6 - 11 ปี</h3>
                            <h3 className='m-0 text-lg'> จำนวน {pageData.age_target_06_11.toLocaleString("en-US")} คน </h3>
                          </>
                      }

                    </div>

                    <div className="text-center">
                      {
                        lang === 'EN' ?
                          <>
                            <h3 className='m-0 text-md'> ages 12 - 14 years</h3>
                            <h3 className='m-0 text-lg'> {pageData.age_target_12_14.toLocaleString("en-US")} persons </h3>
                          </>
                          :
                          <>
                            <h3 className='m-0'> ช่วงอายุ</h3>
                            <h3 className='m-0'> 12 - 14 ปี</h3>
                            <h3 className='m-0 text-lg'> จำนวน {pageData.age_target_12_14.toLocaleString("en-US")} คน </h3>
                          </>
                      }

                    </div>

                    <div className="text-center">
                      {
                        lang === 'EN' ?
                          <>
                            <h3 className='m-0 text-md'> ages 15 - 17 years</h3>
                            <h3 className='m-0 text-lg'> {pageData.age_target_15_17.toLocaleString("en-US")} persons </h3>
                          </>
                          :
                          <>
                            <h3 className='m-0'> ช่วงอายุ </h3>
                            <h3 className='m-0'> 15 - 17 ปี</h3>
                            <h3 className='m-0 text-lg'> จำนวน {pageData.age_target_15_17.toLocaleString("en-US")} คน </h3>
                          </>
                      }
                    </div>

                  </div>
                  <div className="">
                    {
                      < ReanderChartItem
                        data={{
                          percent3_5: pageData.percent3_5,
                          percent6_11: pageData.percent6_11,
                          percent12_14: pageData.percent12_14,
                          percent15_17: pageData.percent15_17
                        }}
                      />
                    }

                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-11">
              <h3 className="font-bold text-2xl mb-2 text-[#0E3165]">{T("OOSC_target_2022")}</h3>
              <div className="grid grid-cols-12 gap-2 md:gap-12 w-full">
                <div className="col-span-12 md:col-span-5 lg:col-span-3 ">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl p-4 h-[300px]">
                    <div className='flex flex-row justify-around '>
                      <div className="flex flex-col m-2">
                        <img className="flex self-center w-[120px]" src={children2} alt="images" />
                        {
                          lang === 'EN' ?
                            <>
                              <h3 className='m-0 text-lg text-center mt-3 '>{pageData.sum_survey.toLocaleString('en-US')} children and youth with no data in the education system</h3>
                            </>
                            :
                            <>
                              {/* 17,616 children and youth with no data in the education system */}
                              <h3 className='m-0 text-lg text-center mt-3 '>เด็กและเยาวชนที่ไม่มีข้อมูล</h3>
                              <h3 className='m-0 text-lg text-center'>ในระบบการศึกษาทั้งหมด</h3>
                              <h3 className='m-0 text-2xl text-center text-[#22C55E]'>{pageData.sum_survey.toLocaleString('en-US')}</h3>
                              <h3 className='m-0 text-xl text-center'>คน</h3>
                            </>
                        }

                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-span-12 lg:col-span-9">
                  <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full md:h-[300px] p-1">
                    <div className="px-6 py-4">
                      <div className="flex flex-col ml-4">
                        <div className="font-bold text-xl mb-2 text-center">{T("OOSC_TABLE_AGE")} </div>
                      </div>
                      <div className='border-t-2 w-full h-[0.5px]'></div>
                      <div className="flex flex-col">

                        < div className="font-bold text-lg mt-2 text-black-800">{lang === 'EN' ? 'ages 3 - 5 years' : 'ช่วงอายุ 3 - 5 ปี'}</div>
                        <div className="w-full flex flex-row content-center " >
                          <div
                            style={{ width: pageData.percent3_5 * 0.85 + '%' }}
                            className={`transition-all ease-out duration-1000 h-[18px] bg-[#00C896] relative rounded-md`}
                          />
                          < div className="font-bold text-md ml-2 text-bla ck-800">{`${pageData.oosc_survey_3_5?.toLocaleString('en-US')}`} {T("persons")} ({pageData.percent3_5.toFixed(2)}%)</div>
                        </div>

                        < div className="font-bold text-lg mt-2 text-black-800">{lang === 'EN' ? 'ages 6 - 11 years' : 'ช่วงอายุ 6 - 11 ปี'}</div>
                        <div className="w-full flex flex-row content-center " >
                          <div
                            style={{ width: pageData.percent6_11 * 0.85 + '%' }}
                            className={`transition-all ease-out duration-1000 h-[18px] bg-[#24F52C] relative rounded-md`}
                          />
                          < div className="font-bold text-md ml-2 text-bla ck-800">{`${pageData.oosc_survey_6_11?.toLocaleString('en-US')}`} {T("persons")} ({pageData.percent6_11.toFixed(2)}%)</div>
                        </div>


                        < div className="font-bold text-lg mt-2 text-black-800">{lang === 'EN' ? 'ages 12 - 14 years' : 'ช่วงอายุ 12 - 14 ปี'}</div>
                        <div className="w-full flex flex-row content-center " >
                          <div
                            style={{ width: pageData.percent12_14 * 0.85 + '%' }}
                            className={`transition-all ease-out duration-1000 h-[18px] bg-[#4BAF67] relative rounded-md`}
                          />
                          < div className="font-bold text-md ml-2 text-bla ck-800">{`${pageData.oosc_survey_12_14?.toLocaleString('en-US')}`} {T("persons")} ({pageData.percent12_14.toFixed(2)}%)</div>
                        </div>


                        < div className="font-bold text-lg mt-2 text-black-800">{lang === 'EN' ? 'ages 15 - 17 years' : 'ช่วงอายุ 15 - 17 ปี'}</div>
                        <div className="w-full flex flex-row content-center " >
                          <div
                            style={{ width: pageData.percent15_17 * 0.85 + '%' }}
                            className={`transition-all ease-out duration-1000 h-[18px] bg-[#32A49D] relative rounded-md`}
                          />
                          < div className="font-bold text-md ml-2 text-bla ck-800">{`${pageData.oosc_survey_15_17?.toLocaleString('en-US')}`} {T("persons")} ({pageData.percent15_17.toFixed(2)}%)</div>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>


              </div>

            </div>


          </div>

          <div className="max-w rounded-xl overflow-hidden shadow-[0_0px_16px_rgba(17,17,26,0.1)] bg-white w-full mt-10 p-2">

            <header className="text-gray-600 body-font">
              <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <nav className="flex lg:w-1/5 flex-wrap items-center text-base md:ml-auto">
                </nav>
                <p className="w-3/5 items-center justify-center font-bold text-2xl mb-2  text-[#048967] text-center">
                  {T("OOSC_MAP")}
                </p>
                <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">

                  {
                    lang !== 'EN' &&
                    <button class="inline-flex items-center bg-emerald-500 border py-1 px-3 focus:outline-none hover:bg-emerald-700 rounded-full text-base mt-4 md:mt-0 text-white"
                      onClick={() => navigate('/fullmap-abe-oosc')}>
                      สำรวจข้อมูลโครงการ
                      <svg fill="none" stroke="currentColor" s troke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </button>
                  }
                </div>

              </div >
            </header >

            <div className="w-full h-[1px] bg-gray-300 mb-4" > </div>

            <div className="grid grid-cols-11 gap-4 w-full">
              <div className="col-span-11 justify-center">
                <div className="flex flex-col p-0 m-0">
                  < div className="flex flex-col md:flex-row" >
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("year_data")}: </h5>
                      <Select
                        className='new-design-select'
                        showSearch
                        placeholder="ข้อมูลปี"
                        // defaultValue={filter.year}
                        value={filter.year}
                        style={{ width: 180, margin: 4 }}
                        onChange={(val) => {
                          setFilter({ ...filter, year: val })
                        }}
                      >
                        {
                          [
                            { value: '2563', text: T("Y2563") },
                            { value: '2562', text: T("Y2562") }
                          ]
                            .filter(x => x.value !== 'ไม่ระบุ')
                            .sort()
                            .map(x => <Option value={x.value}>{x.text}</Option>)
                        }
                      </Select>
                    </div>
                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("province")}: </h5>
                      <Select
                        className='new-design-select'
                        placeholder="จังหวัด"
                        showSearch
                        value={filter.province_name}
                        onChange={(val) => {
                          if (val === 'ทั้งหมด') {
                            setFilter({
                              ...filter,
                              province_name: "ทั้งหมด",
                              view: 'country',
                              city_name: null,
                            })
                          } else {
                            setFilter({
                              ...filter,
                              province_name: val,
                              view: 'province',
                              city_name: null,
                            })
                          }
                        }}
                        style={{ width: 200, margin: 4, fontFamily: 'Kanit' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                        {
                          initData &&
                          [...new Set(initData.map(x => x.province_name))]
                            .filter(x => x !== 'ไม่ระบุ' && LIST_PROVICNE.includes(x))
                            .sort()
                            .map(x => <Option value={x}>{ENG_Addrees(x, lang)}</Option>)
                        }

                      </Select>
                    </div>

                    <div>
                      <h5 className="text-sm m-0 ml-[9px]" >{T("district")}: </h5>

                      <Select
                        className='new-design-select'
                        showSearch
                        value={filter.city_name}
                        placeholder={ENG_Addrees('เลือกอำเภอ', lang)} style={{ width: 200, margin: 4 }} disabled={filter.view === 'country'}
                        onChange={(val) => {
                          if (val === "ทั้งหมด") {
                            setFilter({
                              ...filter,
                              view: 'province',
                              city_name: null,
                            })
                          } else {
                            setFilter({
                              ...filter,
                              city_name: val,
                              view: 'city',
                            })
                          }

                        }}
                      >
                        <Option value={'ทั้งหมด'}>{ENG_Addrees('ทั้งหมด', lang)}</Option>
                        {
                          initData &&
                          [...new Set(initData.filter(x => filter.province_name === x.province_name || filter.province_name === null).map(x => x.city_name))]
                            .sort()
                            .filter(x => x !== 'ไม่ระบุ')
                            .map(x => <Option value={x}>{ENG_Addrees(x, lang)}</Option>)
                        }

                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-11">
                <div className="relative w-full h-full rounded overflow-hidden shadow-card bg-slate-500">
                  <Mapbox
                    id={'map-ำแก'}
                    filter={filter}
                    data={data_map}
                    className="fullmap-cct-container"
                    style={"border_basic"}
                    openSideTab={false}
                    notic_title={'จำนวนโรงเรียนในประเทศไทย'}
                    onclickBorder={onclickBorder}
                    lang={lang}
                  />
                  <div className={`absolute top-[90px] left-2 z-1}`}>
                    <div className='text-center justify-center mt-2 mx-auto'>
                      {filter.view !== "country" ? <button class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full" onClick={() => onBack()}>
                        <ArrowBackIcon sx={{ fontSize: 18, color: 'white' }} /> {T("back")}
                      </button> : <></>}
                    </div>
                  </div>
                  <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
                    <div className='h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3'>
                      <span className='font-bold center mx-1 mb-2'>{T("OOSC_SURVEYED")}</span>
                      <div className='flex'>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#1b5e20]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("MAX")}
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#388e3c]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#66bb6a]`}>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#a5d6a7]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("MIN")}
                          </div>
                        </div>
                        <div className='ml-4  flex flex-col'>
                          <div class={`h-3 w-8 md:w-16  bg-[#94a3b8]`}>
                          </div>
                          <div class="font-bold text-center text-[10px] md:text-md">
                            {T("NO_DATA")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <RefDataDate
              title={T("OOSC_REF")}
              subjectTitle={T('PROCESSED_APR_2565')}
            />

          </div >
        </div>
        <div className='h-[120px]'></div>
      </div>

    </Layout>
  )
}

export default PageOOSC